<template>
    <router-view></router-view>
  <!-- <router-view v-slot="{ Component }">
    <transition>
      <keep-alive :include="includeList">
        <component :is="Component" />
      </keep-alive>
    </transition>
  </router-view> -->
</template>

<script>
import { ref, defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
  setup () {
    // 需要缓存的组件name值
    const includeList = ref(['Home', 'Holdall', 'Watermarkall', 'Dubbinglist', 'Picture', 'Detail/', 'Goods', 'Details/', 'Video/', 'Movie', 'Moviedetail/', 'News', 'Newsdetail/'])
    return {
      includeList
    }
  }
})
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
