import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
const Home = () => import('../views/home/Home')
const Fuzhi = () => import('../views/fuzhi/Fuzhi')
const List = () => import('../views/fuzhi/List')
const Chongfa = () => import('../views/chongfa/Chongfa')
// const ChongfaList = () => import('../views/chongfa/List')
const ChongfaTask = () => import('../views/chongfa/Task')
const Alibaba = () => import('../views/rukou/Alibaba')
const Chuangyuanshangpin = () => import('../views/rukou/Chuangyuanshangpin')
const fuzhiHelp = () => import('../views/help/Fuzhi')
const ChongfaHelp = () => import('../views/help/Chongfa')
const Piliang = () => import('../views/piliang/Piliang')
const Shangpinguanli = () => import('../views/shangpinguanli/Shangpinguanli')

const routes = [
  {
    path: '',
    name: 'DefaultHome',
    component: Home,
    meta: {
      title: '创源软件'
    }

  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '创源软件'
    }
  },
  {
    path: '/fuzhi',
    name: 'Fuzhi',
    component: Fuzhi,
    meta: {
      title: '单商品复制'
    }
  },
  {
    path: '/fuzhi/list',
    name: 'List',
    component: List,
    meta: {
      title: '单商品复制'
    }
  },
  {
    path: '/chongfa',
    name: 'Chongfa',
    component: Chongfa,
    meta: {
      title: '自动重发'
    }
  },
  // {
  //   path: '/chongfa/list',
  //   name: 'ChongfaList',
  //   component: ChongfaList,
  //   meta: {
  //     title: '重发记录'
  //   }
  // },
  {
    path: '/chongfa/task',
    name: 'ChongfaTask',
    component: ChongfaTask,
    meta: {
      title: '重发记录'
    }
  },
  {
    path: '/rukou/alibaba',
    name: 'Alibaba',
    component: Alibaba,
    meta: {
      title: '授权中...'
    }
  },
  {
    path: '/rukou/chuangyuanshangpin',
    name: 'Chuangyuanshangpin',
    component: Chuangyuanshangpin,
    meta: {
      title: '授权中...'
    }
  },
  {
    path: '/fuzhi/help',
    name: 'fuzhiHelp',
    component: fuzhiHelp,
    meta: {
      title: '复制帮助中心'
    }
  },
  {
    path: '/chongfa/help',
    name: 'ChongfaHelp',
    component: ChongfaHelp,
    meta: {
      title: '重发帮助中心'
    }
  },
  {
    path: '/piliang',
    name: 'Piliang',
    component: Piliang,
    meta: {
      title: '批量修改'
    }
  },
  {
    path: '/shangpinguanli',
    name: 'Shangpinguanli',
    component: Shangpinguanli,
    meta: {
      title: '商品管理'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 如果没有登录， 在这里到login
  if (to.meta.isAuthRequired && store.state.user.isLogin === 'false') {
    // ElMessage('只是一条消息提示')
    return next('/login')
  } else {
    next()
  }
  document.title = to.meta.title
})

export default router
